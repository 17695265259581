<template>
    <CustomNoHeaderModalVue
        v-if="isVisible"
        height="1000px"
        :title="''"
        @handleClose="isVisible = false"
        :close="!true"
    >
      <template v-slot:header>
        <div
            class="flex flex-col md:flex-row md:justify-between md:items-center gap-5 border-b p-1"
        >
          <div class="flex flex-col">
            <h2
                class="text-xl md:text-2xl font-semibold font-poppins text-gray-900"
            >
            {{ $t('Home.notificationSignUp.header') }}
            </h2>
          </div>
          <!-- Close Button -->
          <div>
            <button @click="closeModal" class="close-btn">
              × <!-- This is a multiplication sign which resembles a close icon. Alternatively, you can use an SVG or font icon. -->
            </button>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="mb-2">
            <label class="text-xl mb-2 block" for="full_name">{{ $t('Home.notificationSignUp.name') }}:</label>
            <input 
                :class="{'border-red-500': formErrors.full_name}" 
                class="email-input" 
                type="text" 
                id="full_name" 
                v-model="signUpForm.full_name" 
                placeholder="Sven Svensson"
            />
            <span class="text-red-500 text-sm">{{ formErrors.full_name }}</span>
        </div>
        <div class="mb-2">
            <label class="text-xl mb-2 block" for="email">{{ $t('Home.notificationSignUp.email') }}:</label>
            <input 
                :class="{'border-red-500': formErrors.email}" 
                class="email-input" 
                type="email" 
                id="email" 
                v-model="signUpForm.email" 
                placeholder="sven@minmejl.se"
            />
            <span class="text-red-500 text-sm">{{ formErrors.email }}</span>
        </div>
        <div class="mb-2">
            <label class="text-xl mb-2 block" for="phone_number">{{ $t('Home.notificationSignUp.phone_number') }}:</label>
            <input class="email-input" type="text" id="phone_number" v-model="signUpForm.phone_number" placeholder="0700123456" />
        </div>
      </template>

      <template v-slot:footer>
        <button class="subscribe-btn" @click="submitEmail">{{ $t('Home.notificationSignUp.submit') }}</button>
      </template>

    </CustomNoHeaderModalVue>
  </template> 
  
  <script>
  import CustomNoHeaderModalVue from "@/@core/customComponent/CustomNoHeaderModal.vue";
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  
  export default {
    components: {
        CustomNoHeaderModalVue, ToastificationContent
    },
    data() {
      return {
        signUpForm : {
            email : '',
            full_name : '',
            phone_number : '',
        },
        formErrors: {
            email: null,
            full_name: null
        }
      };
    },
    props: {
        isVisible: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        showPopup() {
            return this.isVisible;
        }
    },
    methods: {
        submitEmail() {
            let valid = true;

            if (!this.signUpForm.email) {
                this.formErrors.email = "Ange mejladress.";
                valid = false;
            } else {
                this.formErrors.email = null;
            }

            if (!this.signUpForm.full_name) {
                this.formErrors.full_name = "Ange namn.";
                valid = false;
            } else {
                this.formErrors.full_name = null;
            }

            if (!valid) {
                return;
            } else {
            this.processing = true
            this.$useJwt.createLaunchNotification(this.signUpForm)
            .then(res => {
                let msg = res.data.message;
                this.processing = false
                this.clearFormFieldAndCloseModal()
                this.popup(
                    msg,
                    'success',
                    this.$t('Message.Success'),
                    'CoffeeIcon'
                );
            })
            .catch(err => {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message,
                    'AlertTriangleIcon',
                    'danger'
                );
            })
        }
    },
    clearFormFieldAndCloseModal () {
        this.signUpForm.email = '';
        this.signUpForm.full_name = '';
        this.signUpForm.phone_number = '';
        this.closeModal();
    },
    closeModal() {
        this.$emit('update:isVisible', false); // closes the modal
    },
    popup(message, variant, title, icon) {
        this.$toast({
            component: ToastificationContent,
            props: {
                title: title,
                icon: icon,
                variant: variant,
                text: message,
            },
        });
    },
    }
  };
  </script>
  
  <!-- Include styles if required -->
  <style scoped>
    .subscribe-btn {
        background-color: #16149F;  /* Primary color (e.g., blue) */
        color: #FFFFFF;             /* Text color (e.g., white) */
        padding: 12px 20px;         /* Padding around the text */
        border: none;               /* Removing the default border */
        border-radius: 4px;         /* Rounded corners */
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 4px 2px;
        cursor: pointer;            /* Change the cursor on hover */
        transition: background-color 0.3s ease; /* Smooth transition */
    }

    .subscribe-btn:hover {
        background-color: #007BFF;  /* Darken the button color on hover */
    }
    .email-input {
        width: 100%;                /* Take the full width of the parent */
        padding: 10px 15px;         /* Add some padding */
        font-size: 1rem;            /* Increase font size */
        border: 1px solid #ccc;     /* Add a border */
        border-radius: 4px;         /* Rounded borders */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a slight shadow for depth */
        outline: none;              /* Remove the default outline */
        transition: border-color 0.3s ease; /* Smooth transition for hover and focus changes */
    }

    .email-input:focus {
        border-color: #007BFF;      /* Change border color on focus, can match button color for consistency */
    }

    .close-btn {
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
        transition: color 0.3s;
    }

    .close-btn:hover {
        color: #007BFF; /* Change color on hover */
    }
  </style>